import React from 'react'
import { BlogPostPreviewFragmentFragment } from '../../../types/graphql-types'
import Image from 'gatsby-image'
import styled, { ThemeContext } from 'styled-components'
import BaseHeader from '../Header'
import Content from '../Content'
import { useIntl } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'
import Link from '../Link'
import ShareButton from '../ShareButton'
import PublishedOn from '../PublishedOn'
import useMedia from 'use-media'
import Markdown from '../Markdown'

const Wrapper = styled(Content)`
  display: flex;
  align-items: center;
  .unstyled {
    color: #333;
    text-decoration: none;
  }
  @media only screen and (max-width: ${props =>
      props.theme.protraitBreakpoint}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const ImageWrapper = styled.div`
  flex-shrink: 0;
  padding-right: 1.5rem;
  .gatsby-image-wrapper {
    border-radius: 4px;
    overflow: hidden;
  }
  a:focus {
    outline: none;
  }
`
const Details = styled.div``
const Header = styled(BaseHeader)`
  font-size: ${props => props.theme.scale}rem !important;
  text-align: left;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a:focus {
    outline: none;
    text-decoration: underline;
  }
  @media only screen and (max-width: ${props =>
      props.theme.protraitBreakpoint}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  a,
  a:link,
  a:visited {
    color: ${props => props.theme.primary};
    text-decoration: none;
  }
  a:focus {
    outline: none;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: ${props =>
      props.theme.protraitBreakpoint}px) {
    flex-direction: column;
  }
`

interface Props {
  data: BlogPostPreviewFragmentFragment
}

export const query = graphql`
  fragment BlogPostPreviewFragment on CMS_Page {
    id
    title: Title
    exzerpt: Exzerpt
    published: Published
    slug: Slug
    hashtags: Hashtags
    image: HeroImage {
      id
      url
      urlSharp {
        childImageSharp {
          fixed(width: 200, height: 200, quality: 100, fit: COVER) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`

const BlogPostPreview: React.FC<Props> = ({ data }: Props) => {
  const { title, exzerpt = '', slug, published, hashtags } = data
  const intl = useIntl()
  const image = data.image?.urlSharp?.childImageSharp?.fixed
  const to = `/${slug}`
  const theme = React.useContext(ThemeContext)
  const isPortrait = useMedia({ maxWidth: theme.protraitBreakpoint })

  return (
    <Wrapper>
      {image && (
        <ImageWrapper>
          <Link to={to} className="unstyled" tabIndex={-1}>
            <Image fixed={image as any} alt="" />
          </Link>
        </ImageWrapper>
      )}
      <Details>
        <Header as="h2">
          <Link to={to} className="unstyled" tabIndex={-1}>
            {title}
          </Link>
          <ShareButton
            url={to}
            twitterText={title}
            twitterHashtags={hashtags || undefined}
            openLeft={!isPortrait}
          />
        </Header>
        <Markdown source={exzerpt} />
        <BottomSection>
          <Link to={to}>{intl.formatMessage({ id: 'blog.read' })}</Link>
          <PublishedOn published={published} />
        </BottomSection>
      </Details>
    </Wrapper>
  )
}

export default BlogPostPreview

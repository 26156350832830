import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'
import { ResearchQuery } from '../../types/graphql-types'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import HeroImage from '../components/HeroImage'
import BlogPostPreview from '../components/BlogPostPreview'
import Content from '../components/Content'

interface Props {
  data: ResearchQuery
}

const IndexPage: React.FC<Props> = (props: Props) => {
  const { data } = props
  const intl = useIntl()
  const [heroVisible, setHeroVisible] = React.useState(true)

  const siteUrl = data.site!.siteMetadata!.siteUrl!
  const heroImage = data.heroImage!.childImageSharp!.fluid!
  const posts = data.cms.posts || []

  return (
    <Layout
      menuData={(data as any).cms.mainMenu[0]}
      footerMenuData={(data as any).cms.footerMenus}
      languageSwitchData={(data as any).cms.languages}
      heroImageMenubar={heroImage}
      heroIsVisible={heroVisible}
      transparentMenubar
      darkMenu
    >
      <SEO
        title={intl.formatMessage({ id: 'research.title' })}
        description={intl.formatMessage({ id: 'research.description' })}
        link={[
          {
            rel: 'canonical',
            href: `${siteUrl}/${intl.locale}/`,
          },
          {
            rel: 'alternate',
            hrefLang: 'en',
            href: `${siteUrl}/en/`,
          },
          {
            rel: 'alternate',
            hrefLang: 'de',
            href: `${siteUrl}/de/`,
          },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: `${siteUrl}/de/`,
          },
        ]}
        previewImage={heroImage}
        meta={posts.length < 1 ? [{ name: 'robots', content: 'noindex' }] : []}
      />
      <HeroImage
        image={heroImage}
        title={intl.formatMessage({ id: 'research.title' })}
        onVisibilityChange={visible => setHeroVisible(visible)}
      />
      {posts.map(post => (
        <BlogPostPreview key={post!.id} data={post as any} />
      ))}
      {!!(posts.length < 1) && (
        <Content>
          <p style={{ textAlign: 'center' }}>
            <FormattedMessage id="research.noposts" />
          </p>
        </Content>
      )}
    </Layout>
  )
}

export const query = graphql`
  query Research($language: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cms {
      mainMenu: menus(
        where: { Position: "Main", Language: { locale: $language } }
      ) {
        ...MainMenuFragment
      }
      footerMenus: menus(
        where: { Position_ne: "Main", Language: { locale: $language } }
      ) {
        ...FooterMenusFragment
      }

      languages {
        ...LanguageSwitchFragment
      }

      posts: pages(
        where: { Language: { locale: $language }, Type: "ResearchPost" }
        sort: "Published:desc"
      ) {
        ...BlogPostPreviewFragment
      }
    }

    heroImage: file(relativePath: { eq: "research.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export default IndexPage
